var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Topbar"),
      _c(
        "div",
        {
          staticClass: "card shadow mx-3 conteudo text-left",
          staticStyle: { "margin-top": "90px" },
        },
        [
          _c("div", { staticClass: "card-header py-3" }, [
            _c(
              "h6",
              { staticClass: "m-0 font-weight-bold text-primary-dark" },
              [
                _c("i", { class: _vm.title.icon + " mr-2" }),
                _vm._v(_vm._s(_vm.title.text) + " "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.carregando,
                        expression: "carregando",
                      },
                    ],
                    staticClass: "spinner-grow",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _vm.isDataLoaded()
                  ? _c(
                      "fieldset",
                      { attrs: { disabled: _vm.carregando } },
                      [
                        _c("Quadro", {
                          attrs: {
                            type: _vm.messageType,
                            message: _vm.message,
                          },
                        }),
                        _c("form", [
                          _c("div", { staticClass: "d-print-none" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-secondary mr-1",
                                attrs: { id: "btn_voltar" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.go(-1)
                                  },
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-arrow-left" }),
                                _vm._v(" Voltar "),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary mr-1",
                                attrs: { type: "button", id: "btnSave" },
                                on: { click: _vm.salvar },
                              },
                              [_vm._v(" Salvar ")]
                            ),
                            _vm.operation == "update"
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-warning mr-1",
                                    attrs: {
                                      type: "button",
                                      id: "btnReprocessar",
                                    },
                                    on: { click: _vm.abrirModalReprocessar },
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-sync-alt" }),
                                    _vm._v(" Reprocessar Cobranças "),
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-danger mr-1",
                                attrs: {
                                  type: "button",
                                  id: "btn_del",
                                  disabled: _vm.operation === "insert",
                                  "data-toggle": "modal",
                                  "data-target": "#modalExcluir",
                                },
                              },
                              [_vm._v(" Excluir ")]
                            ),
                            _c("hr"),
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-lg-12" }, [
                              _c("fieldset", [
                                _c(
                                  "div",
                                  { staticClass: "form-row" },
                                  [
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "1",
                                        name: "id",
                                        label: "ID",
                                        type: "text",
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.dados[_vm.idname].value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados[_vm.idname],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados[idname].value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "4",
                                        name: "nome",
                                        label: "Nome",
                                        type: "text",
                                        maxlength: "100",
                                        errorMessage: _vm.errors.nome,
                                      },
                                      model: {
                                        value: _vm.dados.nome.value,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dados.nome, "value", $$v)
                                        },
                                        expression: "dados.nome.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "dataini",
                                        label: "Data Início",
                                        type: "date",
                                        errorMessage: _vm.errors.dataini,
                                      },
                                      model: {
                                        value: _vm.dados.dataini.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.dataini,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.dataini.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "datafim",
                                        label: "Data Fim",
                                        type: "date",
                                        errorMessage: _vm.errors.datafim,
                                      },
                                      model: {
                                        value: _vm.dados.datafim.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.datafim,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.datafim.value",
                                      },
                                    }),
                                    _c("SelectAndLabel", {
                                      attrs: {
                                        column: "3",
                                        name: "idtabcob",
                                        label: "Tabela de Cobrança",
                                        options: _vm.combos.idtabcob,
                                        errorMessage: _vm.errors.idtabcob,
                                      },
                                      model: {
                                        value: _vm.dados.idtabcob.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.idtabcob,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.idtabcob.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-row" },
                                  [
                                    _c("SelectAndLabel", {
                                      attrs: {
                                        column: "3",
                                        name: "idempresasfat",
                                        label: "Empresa Faturamento",
                                        options: _vm.combos.idempresasfat,
                                        errorMessage: _vm.errors.idempresasfat,
                                      },
                                      model: {
                                        value: _vm.dados.idempresasfat.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.idempresasfat,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.idempresasfat.value",
                                      },
                                    }),
                                    _c("SelectAndLabel", {
                                      attrs: {
                                        column: "3",
                                        name: "idclientescob",
                                        label: "Cliente Cobrança",
                                        options: _vm.combos.idclientescob,
                                        errorMessage: _vm.errors.idclientescob,
                                      },
                                      model: {
                                        value: _vm.dados.idclientescob.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.idclientescob,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.idclientescob.value",
                                      },
                                    }),
                                    _c("SelectAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "idmodelocob",
                                        label: "Modelo",
                                        options: _vm.combos.idmodelocob,
                                        errorMessage: _vm.errors.idmodelocob,
                                      },
                                      model: {
                                        value: _vm.dados.idmodelocob.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.idmodelocob,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.idmodelocob.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "perc_fixo",
                                        label: "% Fixo",
                                        type: "number",
                                        format: "decimal",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage: _vm.errors.perc_fixo,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.perc_fixo.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.perc_fixo,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.perc_fixo.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "valor_fixo",
                                        label: "Valor Fixo",
                                        type: "number",
                                        format: "decimal",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage: _vm.errors.valor_fixo,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.valor_fixo.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.valor_fixo,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.valor_fixo.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-row" },
                                  [
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "valfatmin",
                                        label: "Valor Faturamento Min",
                                        type: "number",
                                        format: "decimal",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage: _vm.errors.valfatmin,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.valfatmin.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.valfatmin,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.valfatmin.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "valorreversa",
                                        label: "Valor Reversa",
                                        format: "decimal",
                                        type: "number",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage: _vm.errors.valorreversa,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.valorreversa.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.valorreversa,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.valorreversa.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "valoritensclass",
                                        label: "Valor Itens Class.",
                                        type: "number",
                                        format: "decimal",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage:
                                          _vm.errors.valoritensclass,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.valoritensclass.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.valoritensclass,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dados.valoritensclass.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "perc_customedio",
                                        label: "% Custo Médio",
                                        type: "number",
                                        format: "decimal",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage:
                                          _vm.errors.perc_customedio,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.perc_customedio.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.perc_customedio,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dados.perc_customedio.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "mesesgiro",
                                        label: "Meses para composição de giro",
                                        type: "number",
                                        format: "integer",
                                        min: "0",
                                        errorMessage: _vm.errors.mesesgiro,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.mesesgiro.value,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dados.mesesgiro,
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "dados.mesesgiro.value",
                                      },
                                    }),
                                    _c("InputAndLabel", {
                                      attrs: {
                                        column: "2",
                                        name: "giro",
                                        label: "Giro Estoque",
                                        type: "number",
                                        format: "integer",
                                        min: "0",
                                        step: "0.01",
                                        errorMessage: _vm.errors.giro,
                                        right: "",
                                      },
                                      model: {
                                        value: _vm.dados.giro.value,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.dados.giro, "value", $$v)
                                        },
                                        expression: "dados.giro.value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "form-row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobmovflag",
                                          label: "Cob. Movimento",
                                          errorMessage: _vm.errors.cobmovflag,
                                        },
                                        model: {
                                          value: _vm.dados.cobmovflag.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobmovflag,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.cobmovflag.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobfaturamentoflag",
                                          label: "Cob. Faturamento",
                                          errorMessage:
                                            _vm.errors.cobfaturamentoflag,
                                        },
                                        model: {
                                          value:
                                            _vm.dados.cobfaturamentoflag.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobfaturamentoflag,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dados.cobfaturamentoflag.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobinsumo",
                                          label: "Cob. Insumo",
                                          errorMessage: _vm.errors.cobinsumo,
                                        },
                                        model: {
                                          value: _vm.dados.cobinsumo.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobinsumo,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.cobinsumo.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobitensclass",
                                          label: "Cob. Itens Classificados",
                                          errorMessage:
                                            _vm.errors.cobitensclass,
                                        },
                                        model: {
                                          value: _vm.dados.cobitensclass.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobitensclass,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dados.cobitensclass.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobexced",
                                          label: "Cob. Excedente",
                                          errorMessage: _vm.errors.cobexced,
                                        },
                                        model: {
                                          value: _vm.dados.cobexced.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobexced,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.cobexced.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "form-group pt-md-3 ml-md-3 ml-2",
                                    },
                                    [
                                      _c("CheckboxAndLabel", {
                                        attrs: {
                                          name: "cobreversa",
                                          label: "Cob. Reversa",
                                          errorMessage: _vm.errors.cobreversa,
                                        },
                                        model: {
                                          value: _vm.dados.cobreversa.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dados.cobreversa,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "dados.cobreversa.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _c("hr"),
                              ]),
                            ]),
                            _c("div", { staticClass: "col-lg-6" }, [
                              _vm.operation == "update"
                                ? _c("div", { staticClass: "card shadow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header bg-secondary py-3",
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "m-0 font-weight-bold text-white row",
                                          },
                                          [
                                            _vm._m(0),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.carregando,
                                                    expression: "carregando",
                                                  },
                                                ],
                                                staticClass: "spinner-grow",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-0 card-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "collapsed",
                                            attrs: { id: "cnpjCard" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "card card-body bg-light pb-0 pt-2 px-3",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "form-row align-items-center",
                                                  },
                                                  [
                                                    _c("SelectAndLabel", {
                                                      attrs: {
                                                        column: 9,
                                                        name: "cnpj",
                                                        label: "Novo Cliente",
                                                        options:
                                                          _vm.combos.cnpjs,
                                                        input: "",
                                                      },
                                                      model: {
                                                        value: _vm.cnpj,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.cnpj = $$v
                                                        },
                                                        expression: "cnpj",
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-md-3 pt-md-2",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary form-control mt-md-4",
                                                            attrs: {
                                                              type: "button",
                                                              disabled:
                                                                _vm.cnpj
                                                                  .length < 1,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.postarCnpj,
                                                            },
                                                          },
                                                          [_vm._v(" Lançar ")]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-lg-12 table-responsive",
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-sm table-striped table-bordered my-3",
                                              },
                                              [
                                                _vm._m(1),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.dados.cnpjs,
                                                    function (line) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key:
                                                            "cnpj-" +
                                                            line.idcontratos_cnpj,
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Excluir",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalDeleteCnpj(
                                                                        line.idcontratos_cnpj
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-trash-alt",
                                                                }),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(line.cnpj)
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-lg-6 mt-4 mt-lg-0" },
                              [
                                _vm.operation == "update"
                                  ? _c("div", { staticClass: "card shadow" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card-header bg-secondary py-3",
                                        },
                                        [
                                          _c(
                                            "h6",
                                            {
                                              staticClass:
                                                "m-0 font-weight-bold text-white row",
                                            },
                                            [
                                              _vm._m(2),
                                              _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: _vm.carregando,
                                                      expression: "carregando",
                                                    },
                                                  ],
                                                  staticClass: "spinner-grow",
                                                  attrs: { role: "status" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "sr-only" },
                                                    [_vm._v("Loading...")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "p-0 card-body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "collapsed",
                                              attrs: { id: "cnpjCard" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "card card-body bg-light pb-0 pt-2 px-3",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row align-items-center",
                                                    },
                                                    [
                                                      _c("InputAndLabel", {
                                                        directives: [
                                                          {
                                                            name: "mask",
                                                            rawName: "v-mask",
                                                            value:
                                                              "##.###.###/####-##",
                                                            expression:
                                                              "'##.###.###/####-##'",
                                                          },
                                                        ],
                                                        attrs: {
                                                          column: 9,
                                                          name: "cnpjex",
                                                          label: "Novo CNPJ",
                                                          type: "text",
                                                        },
                                                        model: {
                                                          value: _vm.cnpjex,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.cnpjex = $$v
                                                          },
                                                          expression: "cnpjex",
                                                        },
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3 pt-md-2",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn btn-primary form-control mt-md-4",
                                                              attrs: {
                                                                type: "button",
                                                                disabled:
                                                                  _vm.cnpjex
                                                                    .length < 1,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.postarCnpjEx,
                                                              },
                                                            },
                                                            [_vm._v(" Lançar ")]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-lg-12 table-responsive",
                                            },
                                            [
                                              _c(
                                                "table",
                                                {
                                                  staticClass:
                                                    "table table-sm table-striped table-bordered my-3",
                                                },
                                                [
                                                  _vm._m(3),
                                                  _c(
                                                    "tbody",
                                                    _vm._l(
                                                      _vm.dados.cnpjsex,
                                                      function (line) {
                                                        return _c(
                                                          "tr",
                                                          {
                                                            key:
                                                              "cnpj-" +
                                                              line.idcontratos_cnpjex,
                                                          },
                                                          [
                                                            _c("td", [
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-danger",
                                                                  attrs: {
                                                                    "data-toggle":
                                                                      "tooltip",
                                                                    title:
                                                                      "Excluir",
                                                                    role: "button",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function () {
                                                                        return _vm.abrirModalDeleteCnpjEx(
                                                                          line.idcontratos_cnpjex
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-trash-alt",
                                                                  }),
                                                                ]
                                                              ),
                                                            ]),
                                                            _c("td", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.maskCNPJCPF(
                                                                    line.cnpj
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                            _c("div", { staticClass: "col-lg-6 mt-4" }, [
                              _vm.operation == "update"
                                ? _c("div", { staticClass: "card shadow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header bg-secondary py-3",
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "m-0 font-weight-bold text-white row",
                                          },
                                          [
                                            _vm._m(4),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.carregando,
                                                    expression: "carregando",
                                                  },
                                                ],
                                                staticClass: "spinner-grow",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-0 card-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-lg-12 table-responsive",
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-sm table-striped table-bordered my-3",
                                              },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _c("th", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  return _vm.abrirModalMov(
                                                                    "insert"
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v(" Lançar ")]
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Nome"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v(
                                                          "Qtde Ped Início"
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Qtde Ped Fim"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Valor Unidade"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Valor Caixa"),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Valor Pedido"),
                                                      ]),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.dados.movimentos,
                                                    function (line) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key:
                                                            "movimento-" +
                                                            line.idcontratos_mov,
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Editar/Visualizar",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalMov(
                                                                        line.idcontratos_mov
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-edit",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Excluir",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalDeleteMov(
                                                                        line.idcontratos_mov
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-trash-alt",
                                                                }),
                                                              ]
                                                            ),
                                                          ]),
                                                          line.nomeindex == 0
                                                            ? _c(
                                                                "td",
                                                                {
                                                                  staticClass:
                                                                    "text-center align-middle",
                                                                  attrs: {
                                                                    rowspan:
                                                                      line.totalspan,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        line.nome
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                line.qtdepedini
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                line.qtdepedfim
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.decimalFormat(
                                                                  line.valorunidade
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.decimalFormat(
                                                                  line.valorcaixa
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.decimalFormat(
                                                                  line.valorpedido
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-lg-6 mt-4" }, [
                              _vm.operation == "update"
                                ? _c("div", { staticClass: "card shadow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header bg-secondary py-3",
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "m-0 font-weight-bold text-white row",
                                          },
                                          [
                                            _vm._m(5),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.carregando,
                                                    expression: "carregando",
                                                  },
                                                ],
                                                staticClass: "spinner-grow",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-0 card-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-lg-12 table-responsive",
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-sm table-striped table-bordered my-3",
                                              },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _c("th", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  return _vm.abrirModalArm(
                                                                    "insert"
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v(" Lançar ")]
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Armazenamento"),
                                                      ]),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [_vm._v("Valor")]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.dados.armazenamentos,
                                                    function (line) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key:
                                                            "armazenamento-" +
                                                            line.idcontratos_arm,
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Editar/Visualizar",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalArm(
                                                                        line.idcontratos_arm
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-edit",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Excluir",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalDeleteArm(
                                                                        line.idcontratos_arm
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-trash-alt",
                                                                }),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(line.nome)
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.decimalFormat(
                                                                      line.valor
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c("div", { staticClass: "col-lg-6 mt-4" }, [
                              _vm.operation == "update"
                                ? _c("div", { staticClass: "card shadow" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "card-header bg-secondary py-3",
                                      },
                                      [
                                        _c(
                                          "h6",
                                          {
                                            staticClass:
                                              "m-0 font-weight-bold text-white row",
                                          },
                                          [
                                            _vm._m(6),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.carregando,
                                                    expression: "carregando",
                                                  },
                                                ],
                                                staticClass: "spinner-grow",
                                                attrs: { role: "status" },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "sr-only" },
                                                  [_vm._v("Loading...")]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "p-0 card-body" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-lg-12 table-responsive",
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticClass:
                                                  "table table-sm table-striped table-bordered my-3",
                                              },
                                              [
                                                _c("thead", [
                                                  _c(
                                                    "tr",
                                                    {
                                                      staticStyle: {
                                                        "white-space": "nowrap",
                                                      },
                                                    },
                                                    [
                                                      _c("th", [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click:
                                                                function () {
                                                                  return _vm.abrirModalOcorrencia(
                                                                    "insert"
                                                                  )
                                                                },
                                                            },
                                                          },
                                                          [_vm._v(" Lançar ")]
                                                        ),
                                                      ]),
                                                      _c("th", [
                                                        _vm._v("Ocorrencia"),
                                                      ]),
                                                      _c(
                                                        "th",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                        },
                                                        [_vm._v("Qtde.")]
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                                _c(
                                                  "tbody",
                                                  _vm._l(
                                                    _vm.dados.ocorrencias.dados,
                                                    function (line) {
                                                      return _c(
                                                        "tr",
                                                        {
                                                          key:
                                                            "ocorrencia-" +
                                                            line.idcontratos_ocorrencias,
                                                        },
                                                        [
                                                          _c("td", [
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Editar/Visualizar",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalOcorrencia(
                                                                        line.idcontratos_ocorrencias
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fa fa-edit",
                                                                }),
                                                              ]
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                staticClass:
                                                                  "text-danger",
                                                                attrs: {
                                                                  "data-toggle":
                                                                    "tooltip",
                                                                  title:
                                                                    "Excluir",
                                                                  role: "button",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function () {
                                                                      return _vm.abrirModalDeleteOcorrencia(
                                                                        line.idcontratos_ocorrencias
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "fas fa-trash-alt",
                                                                }),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                line.ocorrencia
                                                              )
                                                            ),
                                                          ]),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.decimalFormat(
                                                                      line.qtde
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalReprocessar",
                title: "Reprocessar",
                confirmClass: "btn btn-primary",
                confirmFunction: _vm.reprocessar,
                small: "",
              },
            },
            [
              _c("div", { staticClass: "row text-left" }, [
                _c("div", { staticClass: "col-lg-12" }, [
                  _c("fieldset", [
                    _c(
                      "div",
                      { staticClass: "form-row" },
                      [
                        _c("InputAndLabel", {
                          attrs: {
                            column: "12",
                            name: "dataReprocessar",
                            label: "Data",
                            type: "date",
                          },
                          model: {
                            value: _vm.dataReprocessar,
                            callback: function ($$v) {
                              _vm.dataReprocessar = $$v
                            },
                            expression: "dataReprocessar",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalMov",
                title: "Movimento",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("ContratoMov", {
                    attrs: {
                      id: _vm.movSelecionada,
                      parentSetMovSelecionada: _vm.setMovSelecionada,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteMov",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteMov,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este movimento? ")]
      ),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalArm",
                title: "Armazenamento",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("ContratoArm", {
                    attrs: {
                      id: _vm.armSelecionada,
                      parentSetArmSelecionada: _vm.setArmSelecionada,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.operation == "update"
        ? _c(
            "Modal",
            {
              attrs: {
                id: "modalOcorrencia",
                title: "Ocorrencia",
                confirmClass: "btn btn-danger d-none",
                confirmFunction: function () {
                  return null
                },
                noFooter: "",
              },
            },
            [
              _vm.isDataLoaded()
                ? _c("ContratosOcorrencia", {
                    attrs: {
                      id: _vm.ocorrenciaSelecionado,
                      parentsetOcorrenciaSelecionado:
                        _vm.setOcorrenciaSelecionado,
                      idparent: _vm.dados[_vm.idname].value,
                      parentBaseUrl: _vm.baseUrl,
                      parentGetData: _vm.getData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteArm",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteArm,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este armazenamento? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteCnpj",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteCnpj,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este CNPJ? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteCnpjEx",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteCnpjEx,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este CNPJ? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalExcluir",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deletar,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este contrato? ")]
      ),
      _c(
        "Modal",
        {
          attrs: {
            id: "modalDeleteOcorrencia",
            title: "Confirmar Exclusão",
            confirmClass: "btn btn-danger",
            confirmText: "Excluir",
            confirmFunction: _vm.deleteOcorrencia,
          },
        },
        [_vm._v(" Tem certeza que deseja excluir este ocorrencia? ")]
      ),
      _c("ForbiddenModal"),
      _c("LogoutModal"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-user" }),
      _vm._v(" Cliente "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "white-space": "nowrap" } }, [
        _c("th"),
        _c("th", [_vm._v("Cliente")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-user" }),
      _vm._v(" CNPJs Excludentes "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", { staticStyle: { "white-space": "nowrap" } }, [
        _c("th"),
        _c("th", [_vm._v("CNPJs")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-exchange-alt" }),
      _vm._v(" Movimentos "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-warehouse" }),
      _vm._v(" Armazenamentos "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", { staticClass: "fas fa-tags" }),
      _vm._v(" Ocorrências Fixas "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }