<template>
    <div>
        <Topbar />
        <div class="card shadow mx-3 conteudo text-left" style="margin-top: 90px">
            <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary-dark">
                    <i :class="`${title.icon} mr-2`"></i>{{ title.text }}
                    <div v-show="carregando" class="spinner-grow" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </h6>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-12">
                        <fieldset v-if="isDataLoaded()" :disabled="carregando">
                            <Quadro :type="messageType" :message="message" />
                            <form>
                                <div class="d-print-none">
                                    <a @click="$router.go(-1)" id="btn_voltar" class="btn btn-secondary mr-1">
                                        <i class="fa fa-arrow-left"></i> Voltar
                                    </a>
                                    <button type="button" id="btnSave" class="btn btn-primary mr-1" @click="salvar">
                                        Salvar
                                    </button>
                                    <button
                                        v-if="operation == 'update'"
                                        type="button"
                                        id="btnReprocessar"
                                        class="btn btn-warning mr-1"
                                        @click="abrirModalReprocessar"
                                    >
                                        <i class="fas fa-sync-alt"></i> Reprocessar Cobranças
                                    </button>
                                    <button
                                        type="button"
                                        id="btn_del"
                                        class="btn btn-danger mr-1"
                                        :disabled="operation === 'insert'"
                                        data-toggle="modal"
                                        data-target="#modalExcluir"
                                    >
                                        Excluir
                                    </button>
                                    <hr />
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <fieldset>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="1"
                                                    name="id"
                                                    label="ID"
                                                    type="text"
                                                    v-model="dados[idname].value"
                                                    readonly
                                                />
                                                <InputAndLabel
                                                    column="4"
                                                    name="nome"
                                                    label="Nome"
                                                    type="text"
                                                    maxlength="100"
                                                    v-model="dados.nome.value"
                                                    :errorMessage="errors.nome"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="dataini"
                                                    label="Data Início"
                                                    type="date"
                                                    v-model="dados.dataini.value"
                                                    :errorMessage="errors.dataini"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="datafim"
                                                    label="Data Fim"
                                                    type="date"
                                                    v-model="dados.datafim.value"
                                                    :errorMessage="errors.datafim"
                                                />
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idtabcob"
                                                    label="Tabela de Cobrança"
                                                    :options="combos.idtabcob"
                                                    v-model="dados.idtabcob.value"
                                                    :errorMessage="errors.idtabcob"
                                                />
                                            </div>
                                            <div class="form-row">
                                                <!-- <SelectAndLabel
                                                    column="2"
                                                    name="tipocob"
                                                    label="Tipo Cobrança"
                                                    :options="combos.tipocob"
                                                    v-model="dados.tipocob.value"
                                                    :errorMessage="errors.tipocob"
                                                /> -->
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idempresasfat"
                                                    label="Empresa Faturamento"
                                                    :options="combos.idempresasfat"
                                                    v-model="dados.idempresasfat.value"
                                                    :errorMessage="errors.idempresasfat"
                                                />
                                                <SelectAndLabel
                                                    column="3"
                                                    name="idclientescob"
                                                    label="Cliente Cobrança"
                                                    :options="combos.idclientescob"
                                                    v-model="dados.idclientescob.value"
                                                    :errorMessage="errors.idclientescob"
                                                />
                                                <SelectAndLabel
                                                    column="2"
                                                    name="idmodelocob"
                                                    label="Modelo"
                                                    :options="combos.idmodelocob"
                                                    v-model="dados.idmodelocob.value"
                                                    :errorMessage="errors.idmodelocob"
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="perc_fixo"
                                                    label="% Fixo"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.perc_fixo.value"
                                                    :errorMessage="errors.perc_fixo"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valor_fixo"
                                                    label="Valor Fixo"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valor_fixo.value"
                                                    :errorMessage="errors.valor_fixo"
                                                    right
                                                />
                                            </div>
                                            <div class="form-row">
                                                <InputAndLabel
                                                    column="2"
                                                    name="valfatmin"
                                                    label="Valor Faturamento Min"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valfatmin.value"
                                                    :errorMessage="errors.valfatmin"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valorreversa"
                                                    label="Valor Reversa"
                                                    format="decimal"
                                                    type="number"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valorreversa.value"
                                                    :errorMessage="errors.valorreversa"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="valoritensclass"
                                                    label="Valor Itens Class."
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.valoritensclass.value"
                                                    :errorMessage="errors.valoritensclass"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="perc_customedio"
                                                    label="% Custo Médio"
                                                    type="number"
                                                    format="decimal"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.perc_customedio.value"
                                                    :errorMessage="errors.perc_customedio"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="mesesgiro"
                                                    label="Meses para composição de giro"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    v-model="dados.mesesgiro.value"
                                                    :errorMessage="errors.mesesgiro"
                                                    right
                                                />
                                                <InputAndLabel
                                                    column="2"
                                                    name="giro"
                                                    label="Giro Estoque"
                                                    type="number"
                                                    format="integer"
                                                    min="0"
                                                    step="0.01"
                                                    v-model="dados.giro.value"
                                                    :errorMessage="errors.giro"
                                                    right
                                                />
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobmovflag"
                                                        v-model="dados.cobmovflag.value"
                                                        label="Cob. Movimento"
                                                        :errorMessage="errors.cobmovflag"
                                                    />
                                                </div>
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobfaturamentoflag"
                                                        v-model="dados.cobfaturamentoflag.value"
                                                        label="Cob. Faturamento"
                                                        :errorMessage="errors.cobfaturamentoflag"
                                                    />
                                                </div>
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobinsumo"
                                                        v-model="dados.cobinsumo.value"
                                                        label="Cob. Insumo"
                                                        :errorMessage="errors.cobinsumo"
                                                    />
                                                </div>
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobitensclass"
                                                        v-model="dados.cobitensclass.value"
                                                        label="Cob. Itens Classificados"
                                                        :errorMessage="errors.cobitensclass"
                                                    />
                                                </div>
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobexced"
                                                        v-model="dados.cobexced.value"
                                                        label="Cob. Excedente"
                                                        :errorMessage="errors.cobexced"
                                                    />
                                                </div>
                                                <div class="form-group pt-md-3 ml-md-3 ml-2">
                                                    <CheckboxAndLabel
                                                        name="cobreversa"
                                                        v-model="dados.cobreversa.value"
                                                        label="Cob. Reversa"
                                                        :errorMessage="errors.cobreversa"
                                                    />
                                                </div>
                                            </div>
                                            <hr />
                                        </fieldset>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-user"></i> Cliente
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="collapsed" id="cnpjCard">
                                                    <div class="card card-body bg-light pb-0 pt-2 px-3">
                                                        <div class="form-row align-items-center">
                                                            <SelectAndLabel
                                                                :column="9"
                                                                name="cnpj"
                                                                label="Novo Cliente"
                                                                v-model="cnpj"
                                                                :options="combos.cnpjs"
                                                                input
                                                            />

                                                            <div class="form-group col-md-3 pt-md-2">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-primary form-control mt-md-4"
                                                                    @click="postarCnpj"
                                                                    :disabled="cnpj.length < 1"
                                                                >
                                                                    Lançar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th></th>
                                                                <th>Cliente</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.cnpjs"
                                                                :key="'cnpj-' + line.idcontratos_cnpj"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteCnpj(
                                                                                    line.idcontratos_cnpj
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.cnpj }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-4 mt-lg-0">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-user"></i> CNPJs Excludentes
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="collapsed" id="cnpjCard">
                                                    <div class="card card-body bg-light pb-0 pt-2 px-3">
                                                        <div class="form-row align-items-center">
                                                            <InputAndLabel
                                                                :column="9"
                                                                name="cnpjex"
                                                                label="Novo CNPJ"
                                                                v-model="cnpjex"
                                                                v-mask="'##.###.###/####-##'"
                                                                type="text"
                                                            />

                                                            <div class="form-group col-md-3 pt-md-2">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-primary form-control mt-md-4"
                                                                    @click="postarCnpjEx"
                                                                    :disabled="cnpjex.length < 1"
                                                                >
                                                                    Lançar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th></th>
                                                                <th>CNPJs</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.cnpjsex"
                                                                :key="'cnpj-' + line.idcontratos_cnpjex"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteCnpjEx(
                                                                                    line.idcontratos_cnpjex
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ maskCNPJCPF(line.cnpj) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-exchange-alt"></i> Movimentos
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalMov('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Nome</th>
                                                                <th>Qtde Ped Início</th>
                                                                <th>Qtde Ped Fim</th>
                                                                <th>Valor Unidade</th>
                                                                <th>Valor Caixa</th>
                                                                <th>Valor Pedido</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.movimentos"
                                                                :key="'movimento-' + line.idcontratos_mov"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalMov(line.idcontratos_mov)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteMov(
                                                                                    line.idcontratos_mov
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td
                                                                    v-if="line.nomeindex == 0"
                                                                    :rowspan="line.totalspan"
                                                                    class="text-center align-middle"
                                                                >
                                                                    {{ line.nome }}
                                                                </td>
                                                                <td>{{ line.qtdepedini }}</td>
                                                                <td>{{ line.qtdepedfim }}</td>
                                                                <td>{{ decimalFormat(line.valorunidade) }}</td>
                                                                <td>{{ decimalFormat(line.valorcaixa) }}</td>
                                                                <td>{{ decimalFormat(line.valorpedido) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-warehouse"></i> Armazenamentos
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalArm('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Armazenamento</th>
                                                                <th class="text-right">Valor</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.armazenamentos"
                                                                :key="'armazenamento-' + line.idcontratos_arm"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalArm(line.idcontratos_arm)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteArm(
                                                                                    line.idcontratos_arm
                                                                                )
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.nome }}</td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.valor) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-tags"></i> Ocorrências Fixas
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th>
                                                                    <button
                                                                        type="button"
                                                                        class="btn btn-primary"
                                                                        @click="() => abrirModalOcorrencia('insert')"
                                                                    >
                                                                        Lançar
                                                                    </button>
                                                                </th>
                                                                <th>Ocorrencia</th>
                                                                <th class="text-right">Qtde.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.ocorrencias.dados"
                                                                :key="'ocorrencia-' + line.idcontratos_ocorrencias"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Editar/Visualizar"
                                                                        role="button"
                                                                        class="text-primary"
                                                                        @click="
                                                                            () => abrirModalOcorrencia(line.idcontratos_ocorrencias)
                                                                        "
                                                                    >
                                                                        <i class="fa fa-edit"></i>
                                                                    </a>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="
                                                                            () =>
                                                                                abrirModalDeleteOcorrencia(line.idcontratos_ocorrencias)
                                                                        "
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.ocorrencia }}</td>
                                                                <td class="text-right">
                                                                    {{ decimalFormat(line.qtde) }}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-6 mt-4">
                                        <div class="card shadow" v-if="operation == 'update'">
                                            <div class="card-header bg-secondary py-3">
                                                <h6 class="m-0 font-weight-bold text-white row">
                                                    <div class="align-self-center">
                                                        <i class="fas fa-ruler"></i> Tamanhos
                                                    </div>
                                                    <div v-show="carregando" class="spinner-grow" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </h6>
                                            </div>

                                            <div class="p-0 card-body">
                                                <div class="collapsed" id="tamanhoCard">
                                                    <div class="card card-body bg-light pb-0 pt-2 px-3">
                                                        <div class="form-row align-items-center">
                                                            <select-and-label
                                                                :column="9"
                                                                name="idtamanho_val"
                                                                label="Lançar Tamanho"
                                                                :options="combos.tamanho_val"
                                                                v-model="idtamanho_val"
                                                                input
                                                            />

                                                            <div class="form-group col-md-3 pt-md-2">
                                                                <button
                                                                    type="button"
                                                                    class="btn btn-primary form-control mt-md-4"
                                                                    @click="postarTamanho"
                                                                    :disabled="idtamanho_val === ''"
                                                                >
                                                                    Lançar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 table-responsive">
                                                    <table class="table table-sm table-striped table-bordered my-3">
                                                        <thead>
                                                            <tr style="white-space: nowrap">
                                                                <th></th>
                                                                <th>Tamanho</th>
                                                                <th class="text-right">Cubagem</th>
                                                                <th>Vigência Início</th>
                                                                <th>Vigência Fim</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                v-for="line in dados.tamanhos"
                                                                :key="'tamanho-'+line.idtamanho_val"
                                                            >
                                                                <td>
                                                                    <a
                                                                        data-toggle="tooltip"
                                                                        title="Excluir"
                                                                        role="button"
                                                                        class="text-danger"
                                                                        @click="() => abrirModalDeleteTamanho(line.idtamanho_val)"
                                                                    >
                                                                        <i class="fas fa-trash-alt"></i>    
                                                                    </a>
                                                                </td>
                                                                <td>{{ line.tamanho }}</td>
                                                                <td class="text-right">{{ Number(line.cubagem).toFixed(2).toString().replace(".", ",") }}</td>
                                                                <td>{{ dateFormat(line.vigencia_ini) }}</td>
                                                                <td>{{ dateFormat(line.vigencia_fim) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <Modal
            v-if="operation == 'update'"
            id="modalReprocessar"
            title="Reprocessar"
            confirmClass="btn btn-primary"
            :confirmFunction="reprocessar"
            small
        >
            <div class="row text-left">
                <div class="col-lg-12">
                    <fieldset>
                        <div class="form-row">
                            <InputAndLabel
                                column="12"
                                name="dataReprocessar"
                                label="Data"
                                type="date"
                                v-model="dataReprocessar"
                            />
                        </div>
                    </fieldset>
                </div>
            </div>
        </Modal>
        <Modal
            v-if="operation == 'update'"
            id="modalMov"
            title="Movimento"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <ContratoMov
                v-if="isDataLoaded()"
                :id="movSelecionada"
                :parentSetMovSelecionada="setMovSelecionada"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            id="modalDeleteMov"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteMov"
        >
            Tem certeza que deseja excluir este movimento?
        </Modal>
        <Modal
            v-if="operation == 'update'"
            id="modalArm"
            title="Armazenamento"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <ContratoArm
                v-if="isDataLoaded()"
                :id="armSelecionada"
                :parentSetArmSelecionada="setArmSelecionada"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            v-if="operation == 'update'"
            id="modalOcorrencia"
            title="Ocorrencia"
            confirmClass="btn btn-danger d-none"
            :confirmFunction="() => null"
            noFooter
        >
            <ContratosOcorrencia
                v-if="isDataLoaded()"
                :id="ocorrenciaSelecionado"
                :parentsetOcorrenciaSelecionado="setOcorrenciaSelecionado"
                :idparent="dados[idname].value"
                :parentBaseUrl="baseUrl"
                :parentGetData="getData"
            />
        </Modal>
        <Modal
            id="modalDeleteArm"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteArm"
        >
            Tem certeza que deseja excluir este armazenamento?
        </Modal>
        <Modal
            id="modalDeleteCnpj"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteCnpj"
        >
            Tem certeza que deseja excluir este CNPJ?
        </Modal>
        <Modal
            id="modalDeleteCnpjEx"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteCnpjEx"
        >
            Tem certeza que deseja excluir este CNPJ?
        </Modal>
        <Modal
            id="modalExcluir"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deletar"
        >
            Tem certeza que deseja excluir este contrato?
        </Modal>
        <Modal
            id="modalDeleteOcorrencia"
            title="Confirmar Exclusão"
            confirmClass="btn btn-danger"
            confirmText="Excluir"
            :confirmFunction="deleteOcorrencia"
        >
            Tem certeza que deseja excluir este ocorrencia?
        </Modal>
        <ForbiddenModal />
        <LogoutModal />
    </div>
</template>

<script>
import api, { getUsername } from "../services/api";
import Topbar from "@/components/Topbar";
import InputAndLabel from "@/components/InputAndLabel";
import SelectAndLabel from "@/components/SelectAndLabel";
import DateAndLabel from "@/components/DateAndLabel";
import Quadro from "@/components/Quadro";
import LogoutModal from "@/components/LogoutModal.vue";
import Modal from "@/components/Modal";
import ViewMixin from "./ViewMixin";
import $ from "jquery";
import {
    ClientErrorException,
    DEFAULT_ERROR_MESSAGE,
    PermissaoException,
    ServerErrorException,
    TokenInvalidoException,
    ValidationException,
} from "../services/exceptions";
import ForbiddenModal from "../components/ForbiddenModal.vue";
import CheckboxAndLabel from "../components/CheckboxAndLabel.vue";
import TextareaAndLabel from "../components/TextareaAndLabel.vue";
import ContratoArm from "../views/ContratoArm.vue";
import ContratoMov from "../views/ContratoMov.vue";
import ContratosOcorrencia from "../views/ContratosOcorrencia.vue";
import { getDate, maskCNPJCPF } from "../utils";
import Select3 from "../components/Select3.vue";

export default {
    name: "Contrato",
    mixins: [ViewMixin],
    components: {
        Topbar,
        LogoutModal,
        Modal,
        InputAndLabel,
        SelectAndLabel,
        Quadro,
        ForbiddenModal,
        CheckboxAndLabel,
        DateAndLabel,
        TextareaAndLabel,
        Select3,
        ContratoArm,
        ContratoMov,
        ContratosOcorrencia
    },
    props: ["id"],
    data: function () {
        return {
            idname: "idcontrato",
            baseUrl: "contratos",
            title: {
                icon: "fas fa-file-contract",
                text: "Contrato",
            },
            combos: {
                idcliente: [],
            },
            // dados: {
            //   documento: ''
            // },
            dataReprocessar: null,
            cnpj: "",
            cnpjex: "",
            idtipomov_val: "",
            idarm_val: "",
            idtamanho_val: "",
            armSelecionada: "insert",
            movSelecionada: "insert",
            modalDeleteCnpj: {
                id: "",
            },
            modalDeleteMovimento: {
                id: "",
            },
            modalDeleteTamanho: {
                id: "",
            },
            ocorrenciaSelecionado: "insert",
        };
    },
    beforeRouteLeave(to, from, next) {
        this.areYouSure(next);
    },
    mounted() {
        this.defineOperation(this.id);
        this.getCombo().then(this.getData);
    },
    methods: {
        async getCombo() {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/combosToSave/${this.id}`);
                let data = response.data;
                this.combos = data;
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async getData(id = this.id) {
            this.carregando = true;

            try {
                let response = await api.get(`/${this.baseUrl}/${id}`);
                let data = response.data;
                this.setData(data, [
                    "idstatus",
                    "status",
                    "armazenamentos",
                    "tamanhos",
                    "movimentos",
                    "cnpjs",
                    "cnpjsex",
                    "ocorrencias",
                ]);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async salvar() {
            this.carregando = true;

            try {
                let response;
                let id = this.dados[this.idname].value;
                let newData;
                let fieldsToDelete = [
                    "idstatus",
                    "status",
                    "armazenamentos",
                    "tamanhos",
                    "movimentos",
                    "cnpjs",
                    "cnpjsex",
                    "ocorrencias"
                ];
                if (this.operation === "update") {
                    newData = this.getDataToUpdate(fieldsToDelete);
                    response = await api.put(`/${this.baseUrl}/${id}`, newData);
                } else {
                    newData = this.getDataToInsert(fieldsToDelete);
                    response = await api.post(`/${this.baseUrl}`, newData);
                }
                let data = response.data;
                id = data.id;
                this.dados[this.idname] = { value: id, oldValue: id };
                this.operation = "update";

                this.updateData(newData);

                this.changeURL(`/${this.baseUrl}/${id}`);

                this.setMessage(data.successMessage, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },
        async reprocessar() {
            this.carregando = true;
            $("#modalReprocessar").modal("hide");

            try {
                let response;
                let id = this.dados[this.idname].value;
                response = await api.post(`/${this.baseUrl}/${id}/reprocessar`, { data: this.dataReprocessar });
                let data = response.data;
                // id = data.id;

                this.setMessage(data.successMessage, "success");
                this.errors = {};
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },
        async deletar() {
            this.carregando = true;

            $("#modalExcluir").modal("hide");

            try {
                let id = this.dados[this.idname].value;
                await api.delete(`/${this.baseUrl}/${id}`);

                this.$router.push(`/${this.baseUrl}`);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarCnpj() {
            this.carregando = true;
            $("#cnpjCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let cnpj = this.cnpj;
                response = await api.post(`/${this.baseUrl}/cnpj`, { idcontrato, cnpj });
                let dados = response.data;
                let { id } = dados.result;

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.cnpj = "";

                this.getData(idcontrato);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalReprocessar() {
            if (this.needConfirmToLeave) {
                if (confirm("Você tem alterações não salvas, salvar antes de editar?")) {
                    this.salvar();
                } else {
                    return null;
                }
            }
            (this.dataReprocessar = this.dataReprocessar ?? getDate("lastDayOfLastMonth")),
                $("#modalReprocessar").modal("show");
        },

        abrirModalDeleteCnpj(id) {
            this.modalDeleteCnpj.id = id;
            $("#modalDeleteCnpj").modal("show");
        },

        async deleteCnpj() {
            this.carregando = true;

            $("#modalDeleteCnpj").modal("hide");

            try {
                let id = this.modalDeleteCnpj.id;
                await api.delete(`/${this.baseUrl}/cnpj/${id}`);
                this.dados.cnpjs = this.dados.cnpjs.filter((el) => el.idcontratos_cnpj != id);
                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        async postarCnpjEx() {
            this.carregando = true;
            $("#cnpjCard").collapse("hide");

            try {
                let response;
                let idcontrato = this.dados[this.idname].value;
                let cnpj = this.cnpjex;
                response = await api.post(`/${this.baseUrl}/cnpjex`, { idcontrato, cnpj });
                let dados = response.data;
                let { id } = dados.result;

                this.setMessage(dados.successMessage, "success");
                this.errors = {};
                this.cnpjex = "";

                this.getData(idcontrato);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalDeleteCnpjEx(id) {
            this.modalDeleteCnpj.id = id;
            $("#modalDeleteCnpjEx").modal("show");
        },

        async deleteCnpjEx() {
            this.carregando = true;

            $("#modalDeleteCnpjEx").modal("hide");

            try {
                let id = this.modalDeleteCnpj.id;
                await api.delete(`/${this.baseUrl}/cnpjex/${id}`);
                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        setMovSelecionada(v) {
            this.movSelecionada = v;
        },

        abrirModalMov(id) {
            this.movSelecionada = id;
            $("#modalMov").modal("show");
        },

        abrirModalDeleteMov(id) {
            this.movSelecionada = id;
            $("#modalDeleteMov").modal("show");
        },

        async deleteMov() {
            this.carregando = true;

            $("#modalDeleteMov").modal("hide");

            try {
                let id = this.movSelecionada;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/mov/${id}`);
                this.dados.movimentos = this.dados.movimentos.filter((el) => el.idcontratos_mov != id);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        setArmSelecionada(v) {
            this.armSelecionada = v;
        },

        abrirModalArm(id) {
            this.armSelecionada = id;
            $("#modalArm").modal("show");
        },

        abrirModalDeleteArm(id) {
            this.armSelecionada = id;
            $("#modalDeleteArm").modal("show");
        },

        async deleteArm() {
            this.carregando = true;

            $("#modalDeleteArm").modal("hide");

            try {
                let id = this.armSelecionada;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/arm/${id}`);
                this.dados.armazenamentos = this.dados.armazenamentos.filter((el) => el.idcontratos_arm != id);
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
            }

            this.carregando = false;
        },

        abrirModalOcorrencia(id) {
            if (this.needConfirmToLeave) {
                if (confirm("Você tem alterações não salvas, salvar antes de editar?")) {
                    this.salvar();
                } else {
                    return null;
                }
            }
            this.ocorrenciaSelecionado = id;
            $("#modalOcorrencia").modal("show");
        },

        abrirModalDeleteOcorrencia(id) {
            this.ocorrenciaSelecionado = id;
            $("#modalDeleteOcorrencia").modal("show");
        },

        async deleteOcorrencia() {
            this.carregando = true;

            $("#modalDeleteOcorrencia").modal("hide");

            try {
                let id = this.ocorrenciaSelecionado;
                await api.delete(`/${this.baseUrl}/${this.dados[this.idname].value}/ocorrencia/${id}`);
                // this.dados._ocorrencias = this.dados._ocorrencias.filter((el) => el.idcontratos_ocorrencias != id);

                this.getData();
            } catch (e) {
                if (e instanceof ValidationException) {
                    this.handleValidationException(e);
                } else if (e instanceof PermissaoException) {
                    this.handlePermissaoException();
                } else if (e instanceof TokenInvalidoException) {
                    this.handleTokenInvalidoException();
                } else if (e instanceof ClientErrorException || e instanceof ServerErrorException) {
                    this.setMessage(e.message, "danger");
                } else {
                    this.setMessage(DEFAULT_ERROR_MESSAGE, "danger");
                }
                console.error(e);
            }

            this.carregando = false;
        },

        setOcorrenciaSelecionado(v) {
            this.ocorrenciaSelecionado = v;
        },
    },

    watch: {
        "dados.documento.value": {
            handler: function (newVal, oldVal) {
                // if (oldVal == undefined || newVal.length > oldVal.length) {
                //   this.dados.documento.value = maskCNPJCPF(newVal, true);
                // }
            },
        },
    },
};
</script>

<style></style>
